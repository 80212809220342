.big-number-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 40px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100000;
	background-color: #fff;
	gap: 40px;

	.day-logic {
		position: absolute;
		top: 40px;
	}

	img {
		width: 80px;
		height: auto;
		position: absolute;
		bottom: 40px;
	}

	.big-number-container {
		display: flex;
		transition: 0.5s;

		&.green {
			color: #9ac058;
		}

		&.red {
			color: #c63636;
		}

		.digit-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;

			&.short {
				width: 20px;
			}

			span {
				font-weight: bold;
				font-size: 54px;
			}
		}
	}
}
