#create-update-aside {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 360px;
	height: calc(100vh - 60px);
	border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
	position: fixed;
	top: 60px;
	padding: 10px;
	right: 0;

	.top-view {
		width: 100%;
	}

	.preview-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 5px;

		span {
			font-weight: bold;
			font-size: 1.1rem;
		}

		.link {
			color: var(--clr-text);
			text-decoration: underline;
			font-size: 1rem;
			font-weight: normal;
			cursor: pointer;
		}
	}

	.quick-tip {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		box-shadow: var(--box-shadow);
		background-color: var(--clr-surface);
		border-radius: var(--border-radius);
		padding: 10px;
		width: 100%;
		margin-bottom: 20px;

		.tip-top-title {
			margin-bottom: 10px;
			color: var(--clr-primary);
			font-weight: bold;
		}

		.tip-title {
			margin-bottom: 10px;
			color: var(--clr-text);
			font-weight: bold;
		}

		.tip-content {
			color: var(--clr-text);
		}

		.tip-swapper {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 15px;

			.circle-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				.tip-circle {
					width: 8px;
					height: 8px;
					border-radius: 4px;
					background-color: rgba($color: #000000, $alpha: 0.1);
					margin: 0 5px;

					&.current {
						background-color: rgba($color: #000000, $alpha: 0.5);
					}
				}
			}

			i {
				margin: 0 5px;
				cursor: pointer;

				&.disabled {
					opacity: 0.3;
					pointer-events: none;
				}
			}
		}
	}

	.navigation-container {
		width: 100%;
		padding: 10px 0;

		.save-next {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 10px 20px;
			border-radius: 20px;
			cursor: pointer;

			&:not(.Mui-disabled) {
				&.save {
					background-color: var(--clr-primary);
					span {
						color: #fff;
					}

					i {
						color: #fff;
					}
				}
				&.next {
					border-color: var(--clr-primary);
					span {
						color: var(--clr-primary);
					}

					i {
						color: var(--clr-primary);
					}
				}
			}

			i {
				transform: translateX(-20px);
				transition: 0.3s;
			}

			&:hover {
				i {
					transform: translateX(0);
				}
			}

			&.Mui-disabled {
				.to-hide-on-load {
					opacity: 0.6;
				}
			}
		}
	}
}
