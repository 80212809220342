.passpass-table-container {
	.passpass-table-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		position: sticky;
		bottom: 0;
		padding: 10px 0;
		width: 100%;
		background-color: var(--clr-surface);
		border-top: 1px solid var(--clr-divider);
		margin-top: 20px;

		.selected-rows {
			color: rgba($color: #000000, $alpha: 0.6);
		}

		.rows-per-page {
			display: flex;
			flex-direction: row;
			align-items: center;

			.rows-btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				min-width: 60px;
				border: 1px solid var(--clr-divider);
				border-radius: 5px;
				min-height: 32px;
				padding: 5px;
				font-size: 18px;
				cursor: pointer;
				margin-left: 10px;

				span {
					font-size: 16px;
				}
			}
		}

		.right-container {
			display: flex;
			align-items: center;
			gap: 40px;

			.move-btns {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 5px;

				button {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 32px;
					height: 32px;
					background-color: transparent;
					border: 1px solid var(--clr-divider);
					border-radius: 5px;
					font-size: 18px;

					svg {
						fill: rgba($color: #000000, $alpha: 0.3);
					}

					&:not(.disabled) {
						cursor: pointer;

						&:hover {
							background-color: rgba($color: #000000, $alpha: 0.1);
						}
						svg {
							fill: var(--clr-text);
						}
					}
				}
			}
		}
	}
}

.passpass-table-row-per-page-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	background-color: var(--clr-surface);
	box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
	padding: 5px;
	border-radius: 5px;
	margin-bottom: 10px;

	.row-per-page-prop {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		fill: var(--clr-text);
		min-width: 120px;
		padding: 5px;
		border-radius: 5px;
		cursor: pointer;

		&:hover {
			background-color: rgba($color: #000000, $alpha: 0.05);
		}

		svg {
			opacity: 0;
		}

		&.selected {
			svg {
				opacity: 1;
			}
		}
	}
}
