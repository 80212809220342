.passpass-modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba($color: #000000, $alpha: 0.3);
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-center {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 30px;
		border-radius: 20px;
		width: 90%;
		max-width: 800px;

		.modal-header {
			width: 100%;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid var(--clr-divider);

			span {
				font-size: 1.2rem;
				font-weight: bold;
			}
		}

		.modal-footer {
			padding-top: 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			gap: 20px;
		}
	}
}
