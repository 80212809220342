.dashboard-block-container {
	display: flex;
	flex-direction: column;
	min-width: 280px;

	&.small {
		width: calc(33% - 30px);
		max-width: 450px;
	}

	&.large {
		width: calc(67% - 20px);
		max-width: 950px;
	}

	@media screen and (max-width: 1100px) {
		&.large {
			width: 100%;
			max-width: unset;
		}
		&.small {
			width: 100%;
			max-width: 400px;
		}
	}

	.dashboard-block-header {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		padding-right: 10px;

		.title {
			font-size: 1.2rem;
			font-weight: bold;
		}

		.block-main-link {
			text-decoration: none;
			color: var(--clr-text);

			img {
				margin-left: 10px;
				transition: 0.2s;
			}

			&:hover {
				img {
					transform: translateX(10px);
				}
			}

			* {
				color: var(--clr-strong-text);
			}
		}
	}

	.dashboard-block-content {
		border-radius: 10px;
		padding: 20px;
		border: 0.5px solid rgba(0, 0, 0, 0.168);
		margin-top: 10px;
		min-height: 280px;
	}
}
