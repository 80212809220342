.preview-container-create-event {
	background-color: var(--clr-surface);
	box-shadow: var(--box-shadow);
	border-radius: var(--border-radius);
	overflow: auto;
	max-height: 400px;
	width: 100%;

	$main-grey: #ededed;

	&::-webkit-scrollbar {
		display: none;
	}

	.general-data-preview {
		padding-bottom: 20px;
		position: relative;

		.draft {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 4px 8px;
			border-radius: 4px;
			z-index: 10;
			position: absolute;
			top: 5px;
			right: 5px;
			background-color: var(--clr-surface);
			border-width: 1px;
			border-style: solid;

			span {
				color: inherit;
			}
		}

		.image-top {
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			background-color: rgba($color: #000000, $alpha: 0.6);

			.background {
				width: 100%;
				height: 120px;
				opacity: 0.4;
				object-fit: cover;
				background-color: $main-grey;
			}

			.cover {
				width: calc(100% - 40px);
				object-fit: cover;
				height: 120px;
				position: absolute;
				border-radius: var(--border-radius);
				top: 20px;
				background-color: $main-grey;
			}
		}

		.event-content {
			padding: 0 20px;
			padding-top: 40px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			position: relative;

			> span {
				max-width: 80%;
				&.event-title {
					font-size: 14px;
					font-weight: bold;
				}

				&.event-small-description {
					font-size: 8px;
					margin-top: 10px;
				}

				&.empty {
					background-color: $main-grey;
					border-radius: 2px;
					height: 12px;
					display: block;
				}
			}

			.event-description {
				margin-top: 15px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				font-size: 8px;
				max-width: 80%;
				overflow: hidden;

				&.empty {
					background-color: $main-grey;
					border-radius: 2px;
					height: 40px;
					display: block;
					width: 100%;
					margin-top: 26px;
					overflow: visible;
					position: relative;

					&::before {
						content: "";
						position: absolute;
						top: -16px;
						background-color: $main-grey;
						border-radius: 2px;
						width: 60%;
						height: 12px;
						display: block;
					}
				}

				* {
					font-size: 8px;
					line-height: 8px;
				}

				.h1 {
					font-size: 12px;
					line-height: 15px;
					margin-bottom: 3px;
					font-weight: bold;
				}
				.h2 {
					font-size: 11px;
					margin-bottom: 3px;
					font-weight: bold;
				}
				.h3 {
					font-size: 10px;
					margin-bottom: 3px;
					font-weight: bold;
				}
				.h4 {
					font-size: 9px;
					margin-bottom: 2px;
					font-weight: bold;
				}
				.h5 {
					font-size: 8px;
					margin-bottom: 2px;
					font-weight: bold;
				}
				.h6 {
					font-size: 7px;
					margin-bottom: 2px;
					font-weight: bold;
				}
			}

			.organizer-logo {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-top: 15px;

				img {
					width: 15px;
					height: 15px;
					border-radius: 8px;
					box-shadow: var(--box-shadow);
					margin-right: 5px;
				}

				span {
					font-size: 10px;
				}
			}

			.button-container {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: calc(100% - 40px);
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.274);
				background-color: #fff;
				padding: 4px;
				position: absolute;
				border-radius: 30px;
				bottom: 0;

				.btn {
					padding: 10px 12px;
					background-color: red;
					border-radius: 30px;
					font-size: 12px;
				}
			}
		}
	}
}
