#public-profile {
	.file-input-container {
		border: 0.5px solid rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 20px;
		width: 600px;
		max-width: 100%;

		&.clickable {
			cursor: pointer;
		}

		&.margin {
			margin-bottom: 20px;
		}

		input {
			display: none;
		}

		& > * + * {
			margin-top: 10px;
		}

		.image-container {
			width: 100%;
			max-width: 400px;
			height: 300px;
			position: relative;
			border-radius: 5px;
			border: 1px solid #fff;
			box-shadow: var(--box-shadow);
			overflow: hidden;

			.image-header {
				display: flex;
				flex-direction: row;
				position: absolute;
				top: 0;
				width: 100%;
				align-items: center;
				justify-content: space-between;
				background-color: rgba($color: #000, $alpha: 0.6);

				svg {
					fill: #fff;
				}
			}

			img {
				width: 100%;
				height: 300px;
				object-fit: cover;
			}

			&.gallery {
				height: 200px;

				img {
					height: 200px;
				}
			}
		}
	}

	h1 {
		font-family: "Gabarito";
	}
}

.section-dashboard {
	padding: 30px !important;
	margin: 0px auto;
	background-color: white;
	border-radius: 10px !important;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
}

.section-dashboard__introduction {
	margin-top: 40px;
	h1 {
		font-family: "Gabarito";
		font-weight: 600;
	}
	p {
		margin: 15px 0;
		max-width: 600px;
	}
}
