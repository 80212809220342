.promo-code-modal {
	width: 100%;

	.promo-code-modal-top {
		display: flex;
		gap: 20px;
	}

	.checkbox-container {
		margin: 20px 0;
	}

	.multiple-select {
		width: 100%;
		max-width: 400px;
	}

	.multiple-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-height: 50svh;
		overflow-y: auto;

		.code-form {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			padding: 20px;
			border-radius: 15px;
			background-color: rgba($color: #000000, $alpha: 0.02);
			transition: 0.3s;
			width: 100%;
			gap: 20px;

			> * {
				width: calc(33% - 140px);
			}
		}
	}
}
