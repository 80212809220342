#dashboard {
	padding: 30px;
	width: calc(100% - 35px);
	margin: 35px auto;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);

	a {
		color: var(--clr-text);
	}

	.center-animation {
		width: 40%;
		max-width: 600px;
	}

	h1 {
		font-size: 38px;
		font-weight: 700;
	}

	.cards-container {
		display: flex;
		gap: 50px;
		margin-top: 25px;
		flex-wrap: wrap;

		.card-link {
			text-decoration: none;
			width: 100%;
			background-color: #fcfbfb;
			border-radius: 10px;
			padding: 20px;
			border: 0.5px solid rgba(0, 0, 0, 0.168);
			min-width: 250px;
			width: 30%;
			max-width: 450px;
			position: relative;
			padding-bottom: 60px;
			transition: 0.3s ease;
			flex: 1;

			&:hover {
				border: 0.5px solid rgba(0, 0, 0, 0.576);

				.card {
					img {
						margin-left: 20px;
					}
				}
			}
		}

		.card {
			h2 {
				font-size: 18px;
				font-family: "Gabarito";
				font-weight: 600;
			}

			p {
				font-size: 14px;
				line-height: 1.3rem;
			}

			.card-button {
				position: absolute;
				display: flex;
				align-items: center;
				bottom: 20px;
				img {
					width: 20px;
					height: 20px;
					margin-left: 10px;
					transition: 0.3s ease;
				}
			}
		}
	}

	.next-event-container {
		margin-top: 50px;

		.card {
			margin-top: 15px;
			width: 100%;
			max-width: none;
			border-radius: 10px;
			padding: 20px;
			border: 0.5px solid rgba(0, 0, 0, 0.168);
			background-color: #fcfbfb;
		}
	}
}
