#create-update-navigation {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 240px;
	height: calc(100vh - 50px);
	padding: 16px;
	background-color: var(--clr-surface);
	position: fixed;
	left: 0;
	top: 50px;

	.navigation-block {
		display: flex;
		flex-direction: column;
		width: 100%;

		.navigation-title {
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			margin-top: 20px;
			padding: 4px 8px;
			border-radius: var(--border-radius);
			cursor: pointer;
			font-family: "Gabarito";
			width: 100%;

			&:hover {
				background-color: rgba($color: #000000, $alpha: 0.1);
			}
		}

		&.current {
			.navigation-item {
				&::before {
					background-color: var(--clr-primary);
				}
			}
			.navigation-title {
				background-color: rgba($color: #000000, $alpha: 0.05);
			}
		}

		.navigation-item {
			position: relative;
			font-size: 14px;
			font-style: normal;
			font-weight: normal;
			margin-top: 12px;
			display: flex;
			align-items: center;
			padding-left: 30px;
			cursor: pointer;

			&:hover {
				font-weight: bold;
			}

			&::before {
				content: "";
				position: absolute;
				left: 10px;
				width: 8px;
				height: 8px;
				background-color: var(--clr-divider);
				border-radius: 4px;
			}

			&.err::before {
				background-color: var(--clr-error);
			}
			&.ok::before {
				background-color: var(--clr-success);
			}
		}
	}
}
