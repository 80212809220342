.promo-code-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #fff;
	padding: 20px;
	border-radius: var(--border-radius);
	gap: 40px;

	.promo-code-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;

		.promo-code-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 20px;
			border-radius: 15px;
			background-color: rgba($color: #000000, $alpha: 0.02);
			border: 1px solid transparent;
			transition: 0.3s;
			margin-bottom: 15px;
			width: 100%;

			.column {
				display: flex;
				flex-direction: column;

				.linked-count {
					display: inline-flex;
					flex-direction: row;
					margin-right: 10px;
					gap: 5px;
					color: #4589ff;
				}
			}

			&:not(.disabled) {
				cursor: pointer;
			}

			&:not(.disabled):hover {
				border-color: var(--divider);
			}

			.code {
				span {
					font-weight: bold;
					font-size: 1.2rem;
				}
			}
		}
	}
}
