#account-members {
	width: 100%;

	.table-container-users {
		width: 100%;
		max-width: 800px;
		border: 1px solid var(--clr-divider);
		margin-top: 20px;
		border-radius: var(--border-radius);
	}

	&.full-width {
		.table-container-users {
			max-width: 1200px;
		}
	}

	table {
		width: 100%;
		border-collapse: collapse;

		thead {
			tr {
				th {
					padding: 10px;
				}
			}
		}

		tbody {
			tr {
				td {
					border-top: 1px solid var(--clr-divider);
					padding: 10px;

					&.delete {
						width: 160px;
						padding: 0;
						padding-right: 10px;

						svg {
							cursor: pointer;

							&:hover {
								fill: var(--clr-error);
							}
						}
					}

					.user-name-container {
						display: flex;
						flex-direction: row;
						align-items: center;
						span {
							display: block;
							margin-left: 10px;
							background-color: var(--clr-surface);
							padding: 4px 6px;
							border-radius: 4px;
						}
					}
				}
			}
		}
	}
}
