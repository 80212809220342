.confirm-view {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--clr-modal-background);

	.confirm-view-center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		background-color: white;
		border-radius: var(--border-radius);
		border-radius: 15px;
		overflow: hidden;
		width: 500px;
		padding: 25px;

		.close-button,
		.add-button {
			font-family: "Gabarito";
			font-weight: 600;
			padding: 10px 20px;
			border-radius: 10px;
			font-size: 12px !important;
		}

		.add-button {
			&:not(.Mui-disabled) {
				background-color: #38ba7c;
				border: 2px solid #38ba7c;

				&:hover {
					background-color: transparent;
					color: #38ba7c;
				}
				color: white;
			}

			transition: 0.3s ease;
		}

		.close-button {
			color: white;
			background-color: #f34642;
			border: 2px solid #f34642;
			transition: 0.3s ease;

			&:hover {
				background-color: transparent;
				color: #f34642;
			}
		}

		.confirm-view-header,
		.confirm-view-footer {
			background-color: var(--clr-surface);
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 5px 10px;
			width: 100%;
		}

		.confirm-view-footer {
			justify-content: flex-end;

			button {
				margin-left: 10px;
			}
		}

		.confirm-view-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding: 20px;

			p {
				width: 100%;
				text-align: justify;
				margin: 5px 0;
			}

			.bold {
				font-weight: bold;
				font-size: 1.1rem;
				padding: 0 3px;
			}

			.value-input {
				margin-top: 10px;
			}
		}
	}
}

.main-toast-snack {
	.MuiAlert-action {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.MuiAlert-icon {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.toast-message-container {
		display: flex;
		flex-direction: column;

		.title {
			font-weight: bold;
		}
	}
}
