#account-members {
	.intro-content {
		h1 {
			font-family: "Gabarito";
		}
		p {
			margin-top: 20px;
			max-width: 500px;
		}
	}

	.create-event-btn {
		background-color: #191919;
		border-radius: 10px;
		padding: 10px;
		width: 200px;

		span {
			color: white;
			font-family: "Gabarito";
			transition: 0.3s ease;
			margin-left: -15px;
			font-size: 12px;
		}

		img {
			width: 20px;
			height: 20px;
			opacity: 0;
			transition: 0.3s ease;
			transform: rotate(90deg);
			margin-right: -15px;
		}

		&:hover {
			span {
				margin-left: 5px;
			}
			img {
				opacity: 1;
				transform: rotate(0deg);
				margin-right: 0;
			}
		}
	}
}
