.create-update-form-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.create-update-form-center {
		width: 700px;
		max-width: 90%;
		padding: 20px;
		background-color: var(--clr-background);
		border-radius: var(--border-radius);

		.create-update-footer {
			display: flex;
			justify-content: flex-end;
			gap: 20px;
		}

		.create-update-content {
			margin: 20px 0;

			.input-title-form {
				margin-bottom: 10px;
			}

			.questions-container {
				display: flex;
				flex-direction: column;
				padding-top: 20px;

				.question-item {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.question-form {
						display: flex;
						flex-direction: row;
						gap: 10px;

						.ant-select {
							min-width: 120px;
						}
					}
				}
			}
		}
	}
}
