.next-event-box {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	.next-event-left {
		display: flex;
		flex-direction: column;
		width: 300px;
		padding-right: 20px;
		border-right: 0.5px solid rgba(0, 0, 0, 0.168);
		height: 100%;
		text-decoration: none;

		.img-container {
			width: 100%;
			height: 120px;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
			border-radius: 5px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 5px;
			}

			&.empty {
				background-color: #e7e7e6;
				img {
					opacity: 0.6;
					object-fit: contain;
				}
			}
		}

		span.empty {
			width: 100%;
			height: 18px;
			display: block;
			background-color: #ebebeb;
			border-radius: 5px;
		}

		.title {
			font-size: 18px;
			font-weight: 700;
			margin: 10px 0;

			&.empty {
				max-width: 120px;
				margin-top: 15px;
			}
		}

		&:hover {
			.title {
				text-decoration: underline;
			}
		}

		.date {
			color: #e1612f;
			font-family: "Gabarito";
			font-size: 14px;
			margin-bottom: 5px;

			&.empty {
				background-color: #faece3;
			}
		}

		.address {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.5);
		}
	}

	.next-event-right {
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		width: calc(100% - 300px);
		align-items: flex-start;
		justify-content: space-between;

		.no-events {
			display: block;
			font-family: "Gabarito";
			margin-bottom: 10px;
			font-size: 20px;
			font-weight: 600;
			color: rgba(0, 0, 0, 0.6);
		}

		.ticket-sold {
			display: flex;
			flex-direction: column;

			span {
				font-weight: bold;
			}

			.sold-count {
				font-size: 2rem;
			}
		}

		.buttons-container {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			gap: 10px;
			flex-wrap: wrap;

			.button {
				display: flex;
				padding: 5px 10px;
				align-items: center;
				justify-content: flex-start;
				gap: 9px;
				text-decoration: none;
				background-color: #fff;
				border-radius: 5px;
				cursor: pointer;

				&:hover {
					border: 0.5px solid rgba(0, 0, 0, 0.168);
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		flex-direction: column;

		.next-event-left {
			padding-right: 0;
			border-right: none;
			border-bottom: 0.5px solid rgba(0, 0, 0, 0.168);
			padding-bottom: 20px;
			width: 100%;
		}
		.next-event-right {
			padding-top: 20px;
			padding-left: 0;
			width: 100%;

			.ticket-sold {
				margin-bottom: 20px;
			}
		}
	}
}
