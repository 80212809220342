#summary-aside {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	width: 360px;
	height: calc(100vh - 60px);
	position: fixed;
	top: 60px;
	padding: 5px 10px;
	right: 0;
	overflow-y: auto;

	.aside-btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		border: 1px solid var(--clr-divider);
		border-radius: 5px;
		padding: 10px;
		gap: 10px;
		background-color: var(--clr-surface);
		cursor: pointer;
	}

	.event-visibility {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		box-shadow: var(--box-shadow);
		border-radius: 5px;
		padding: 10px;
		gap: 10px;
		background-color: var(--clr-surface);

		.radio-group {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			gap: 10px;

			input {
				margin-top: 4px;
			}

			label {
				display: flex;
				flex-direction: column;
				cursor: pointer;

				span {
					font-size: 14px;
				}

				.label-title {
					font-weight: bold;
					font-size: 16px;
				}
			}
		}
	}
}
