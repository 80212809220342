@import "../../../mixin.scss";

aside.navigation {
	position: fixed;
	left: 0;
	top: 60px;
	height: calc(100vh - 50px);
	background-color: white;
	border-right: 1px solid var(--clr-divider-light);
	padding: 10px;
	z-index: 1000;
	overflow-y: scroll;

	nav {
		display: flex;
		flex-direction: column;
		width: 40px;
		border-radius: var(--border-radius);
		transition: 0.3s;
		gap: 10px;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	&.active nav {
		width: 250px;
	}
}

.navigation ul {
	width: 40px;
	text-align: center;
	transition: 0.3s;
}

.navigation ul li {
	display: flex;
	list-style: none;
	overflow: hidden;
	padding: 0px 0;
	margin: 5px 0;
}

.log_div {
	padding-left: 20px;
	display: flex;
	flex-direction: row;
}

aside.navigation ul li.active {
	background-color: var(--clr-primary-transparent);
	border-radius: var(--border-radius);
}

.navigation ul li i,
.navigation ul li p {
	color: var(--clr-secondary-text);
}

aside.active nav ul {
	width: 250px;
}

.navigation ul a {
	text-decoration: none;
}

.navigation .navigation_elem {
	display: flex;
	gap: 10px;
	width: 250px;
	flex-direction: row;
	align-items: center;
}

.navigation .navigation_elem img {
	width: 40px;
	height: 40px;
	object-fit: contain;
	font-size: 20px;
	padding: 10px;
}

.navigation .navigation_elem p {
	font-size: 16px;
	font-weight: normal;
	white-space: nowrap;
	font-family: "Gabarito";
}

.navigation .separator {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 300px;
	padding-left: 40px;
	border-top: 1px solid var(--clr-divider);
	margin: 10px 0 0 0;
	padding: 10px 0 10px 40px;
}

.navigation .separator h2 {
	font-size: 16px;
	font-weight: normal;
	color: var(--clr-secondary-text);
	text-transform: uppercase;
	font-family: "Gabarito";
	margin: 10px 0;
	margin-left: -40px;
	margin-top: 20px;
	opacity: 0;
	transition: 0.4s ease;
}

.logo {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.logo h2 {
	font-size: 18px;
	color: var(--clr-strong-text);
	margin-left: 10px;
}

.logo img {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

.nav_button {
	border: 2px solid var(--clr-primary);
	margin: 0 5px;
	padding: 10px 5px;
	border-radius: var(--border-radius);
	background-color: var(--clr-primary);
}

.nav_button.outline {
	background-color: var(--clr-surface);
}

.navigation ul li .nav_button p {
	color: var(--clr-on-primary);
}

.navigation ul li .nav_button.outline p {
	color: var(--clr-primary);
}

.navigation ul li.small {
	display: none;
}

@media screen and (max-width: 900px) {
	.navigation ul li.large {
		display: none;
	}

	.navigation ul li.small {
		display: flex;
	}
}

@include phone {
	aside {
		display: none !important;
	}

	.content {
		padding: 60px 0 0 0;
	}
}

@media (hover: hover) {
	aside nav ul li.hov:hover {
		background-color: var(--clr-primary-transparent);
		border-radius: var(--border-radius);
	}

	aside:hover nav ul {
		width: 250px;
	}

	aside:hover nav {
		width: 250px;
	}

	aside:hover {
		box-shadow: var(--box-shadow);
	}

	aside:hover {
		.separator h2 {
			opacity: 1 !important;
			margin-left: -30px;
		}
	}
}
