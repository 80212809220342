.participation-forms {
	padding: 30px;
	padding-top: 50px;
	width: 100%;

	.form-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
		padding-top: 30px;
		align-items: stretch;
		width: 100%;

		.form-item {
			display: flex;
			flex-direction: column;
			padding: 15px;
			min-width: 200px;
			border-radius: var(--border-radius);
			background-color: var(--clr-surface);
			box-shadow: var(--box-shadow);
			cursor: pointer;

			.form-number {
				font-size: 0.8rem;
				color: var(--clr-secondary-text);
				display: block;
				margin-bottom: 10px;
			}

			.title {
				font-weight: bold;
				font-size: 1.2rem;
			}

			&.new {
				align-items: center;
				justify-content: center;
			}
		}
	}
}
