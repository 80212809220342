@import "../../../mixin.scss";

#settings-base-view {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 300px;

	.save-changes {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		position: fixed;
		bottom: 0;
		right: 0;
		width: calc(100vw - 360px);
		background-color: var(--clr-surface);
		border-top: 1px solid var(--clr-divider);
		padding: 10px 20px;
		z-index: 10;

		button {
			padding: 6px 20px;
			font-family: "Gabarito";
			font-weight: 600;
			color: black;
			transition: 0.3s ease;
		}

		button:first-child {
			margin-right: 20px;

			&:hover {
				background-color: rgba(0, 0, 0, 0.053);
			}
		}

		button:nth-child(2) {
			&:not(.Mui-disabled) {
				background-color: var(--clr-success);

				&:hover {
					opacity: 0.8;
				}
			}

			color: white;
		}

		@media screen and (max-width: 900px) {
			& {
				width: calc(100vw - 60px);
			}
		}

		@include phone {
			& {
				width: 100%;
			}
		}
	}

	.account-navigation {
		position: fixed;
		top: 60px;
		left: 60px;
		min-height: calc(100vh - 50px);
		width: 300px;
		background-color: var(--clr-surface);
		border-right: 1px solid var(--clr-divider);
		z-index: 100;

		li {
			list-style: none;
			background-color: var(--clr-surface);

			a {
				text-decoration: none;
				display: flex;
				padding: 10px;
				border-bottom: 1px solid var(--clr-divider);

				.account-nav-elem {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: flex-start;

					i {
						display: flex;
						justify-content: center;
						width: 30px;
						margin-right: 10px;
						margin-top: 5px;
						font-size: 1rem;
					}
				}
			}

			&.active {
				background-color: var(--clr-background-transparent);
			}
		}

		&.full {
			width: 100%;
		}
	}

	.settings-content {
		padding: 0px 20px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		padding-bottom: 100px;

		p {
			max-width: 750px;
		}

		.settings-center {
			width: 100%;
			.setting-block-title {
				display: flex;
				flex-direction: column;
				width: 100%;
				padding: 20px 0;
				border-bottom: 1px solid var(--clr-divider);
				padding: 30px !important;
				margin: 17px auto;
				background-color: white;
				border-radius: 10px;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
				margin-top: 40px;
				h1 {
					font-family: "Gabarito";
					font-weight: 600;
				}
				p {
					margin-top: 15px;
					max-width: 600px;
				}
			}

			.setting-block {
				display: flex;
				flex-direction: column;
				gap: 50px;
				width: 100%;
				padding: 20px 0;

				padding: 30px !important;
				margin: 17px auto;
				background-color: white;
				border-radius: 10px;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);

				.input-error-explaination {
					font-size: 12px;
					margin-top: 4px !important;
					margin-bottom: 10px;
					color: var(--clr-error);
				}

				.setting-block-form {
					display: flex;
					flex-direction: column;

					> * + * {
						margin-top: 15px;
					}
				}

				.settings-block-action {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					width: 100%;

					button:first-child {
						margin-right: 10px;
					}
				}

				.setting-block-content.column {
					display: flex;
					flex-direction: column;
					align-items: flex-start;

					& > * + * {
						margin-top: 10px;
					}

					&.full-width {
						width: 100%;
					}

					.text-field {
						max-width: 400px;
					}

					.ck-container {
						width: 100%;
						margin-top: 20px;

						.ck-toolbar {
							border-top-right-radius: 10px;
							border-top-left-radius: 10px;
							background-color: transparent;
						}

						.ck-content {
							border-bottom-right-radius: 10px;
							border-bottom-left-radius: 10px;
							background-color: transparent;
						}
					}

					.setting-inside-block {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						a {
							color: #8ab4f8;
							display: block;
							margin-top: 5px;
							margin-bottom: 20px;
						}
						h3 {
							margin: 10px 0;
						}
					}
				}

				.setting-block-description {
					width: 400px;
					margin-right: 40px;
					h2,
					h3 {
						margin-bottom: 10px;
						font-family: "Gabarito";
						font-weight: 600;
					}

					p {
						color: var(--clr-secondary-text);
						max-width: 650px;
					}

					&.large {
						width: 600px;
						max-width: 100%;
					}

					&.full-width {
						width: 100%;
						max-width: unset;
					}
				}

				.block-container.full-width {
					width: 100%;
				}

				&.need-validation {
					flex-direction: column;
					.block-container {
						display: flex;
						flex-direction: row;
						width: 100%;
						padding: 20px 0;
					}
				}
			}

			@include phone {
				.setting-block {
					flex-direction: column;

					.setting-block-description {
						width: 100%;
						margin-right: 0;
						margin-bottom: 20px;
					}

					&.need-validation {
						flex-direction: column;
						.block-container {
							flex-direction: column;
						}
					}
				}
			}

			&.need-space {
				@media screen and (max-width: 1080px) {
					.setting-block {
						flex-direction: column;

						.setting-block-description {
							width: 100%;
							margin-right: 0;
							margin-bottom: 20px;
						}

						&.need-validation {
							flex-direction: column;
							.block-container {
								flex-direction: column;
							}
						}
					}
				}
			}
		}
	}

	&.hide-nav {
		padding-left: 0;
		.account-navigation {
			left: 0;
		}
		.save-changes {
			width: calc(100% - 60px);
		}
	}

	@media screen and (max-width: 900px) {
		.account-navigation.aside {
			display: none;
		}
		padding-left: 0;
	}

	@include phone {
		padding-left: 0;
		.account-navigation {
			left: 0;
		}
	}
}
