.tickets-addon-modal {
	display: flex;
	flex-direction: column;
	width: 90%;
	max-width: 900px;
	background-color: var(--clr-surface);
	border-radius: var(--border-radius);

	.modal-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--clr-divider);
		width: 100%;
		padding: 20px 20px 10px 20px;
	}

	.modal-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		padding: 20px;
		max-height: calc(90svh - 200px);
		overflow-y: auto;

		.ticket-setting-block {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-bottom: 20px;

			.ticket-setting-title {
				font-weight: bold;
				font-size: 1.2rem;
				display: block;
				margin-bottom: 10px;
			}

			.ticket-setting-content {
				display: flex;
				width: 100%;
				gap: 20px;

				.ticket-setting-input-div {
					display: flex;
					flex-direction: column;
					width: 100%;
					gap: 10px;
					max-width: 200px;

					.classic-date-input {
						border: 0.5px solid rgba(0, 0, 0, 0.2);
						border-radius: 5px;
						outline: none;
						padding: 10px 10px;
					}

					&.full-width {
						max-width: unset;
					}
				}
			}
		}
	}

	.modal-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 20px 20px 20px;
		gap: 20px;
	}
}
