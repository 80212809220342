.passpass-table-container {
	.passpass-table-content {
		width: 100%;
		display: flex;
		border-radius: 10px;
		border: 1px solid var(--clr-divider);
		overflow-x: auto;

		table {
			border-collapse: collapse;
			width: 100%;

			thead,
			tbody {
				tr {
					border-bottom: 1px solid var(--clr-divider);

					td {
						padding: 12px;
					}
				}
			}

			thead {
				td {
					.column-header {
						color: var(--clr-secondary-text);
					}
				}
			}

			tbody {
				tr:last-child {
					border-bottom: none;
				}

				tr:hover {
					td {
						background-color: rgba($color: #000000, $alpha: 0.02);
					}
				}

				tr.selected {
					td {
						background-color: rgba($color: #000000, $alpha: 0.04);
					}
				}
			}

			* {
				white-space: nowrap;
			}

			.checkbox {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 16px;
				height: 16px;
				border-radius: 4px;
				border: 1px solid var(--clr-text);
				box-shadow: 0 2px 4px rgba($color: #000, $alpha: 0.1);
				cursor: pointer;
				background-color: #fff;
				transition: 0.3s;

				svg {
					fill: var(--clr-surface);
				}

				&.selected {
					background-color: var(--clr-text);
				}

				&.part-selected {
					svg {
						fill: var(--clr-text);
					}
				}
			}
		}
	}
}
