.participation-form-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: #fff;
	padding: 20px;
	border-radius: var(--border-radius);
	gap: 40px;
	position: relative;

	.form-description {
		span {
			font-weight: bold;
		}
	}

	.row {
		display: flex;
		flex-direction: row;
		gap: 20px;

		.fill-selection {
			border-radius: var(--border-radius);
			padding: 20px;
			border: 1px solid transparent;
			width: calc(50% - 10px);
			cursor: pointer;
			background-color: rgba($color: #000000, $alpha: 0.02);

			&.selected {
				transition: 0.3s;
				border-color: var(--clr-divider);
			}

			.fill-selection-header {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 10px;

				.icon-container {
					background-color: rgba($color: #000000, $alpha: 0.02);
					width: 48px;
					height: 48px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 10px;
				}
			}
		}
	}

	.target-container {
		> span {
			display: block;
			margin-bottom: 15px;
		}

		.select-item {
			max-width: 400px;
		}
	}

	.field-block {
		width: 100%;

		.field-header {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;

			.filter-container {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				width: 50%;

				> span {
					display: block;
					margin-bottom: 15px;
				}

				.select-item {
					width: 100%;
				}
			}
		}

		.field-container {
			margin-top: 20px;
			> span {
				font-weight: bold;
				font-size: 1rem;
				display: block;
				margin-bottom: 15px;
			}

			.questions-wrapper {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				width: 100%;
			}

			.field-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 20px;
				border-radius: 15px;
				background-color: rgba($color: #000000, $alpha: 0.02);
				border: 1px solid transparent;
				transition: 0.3s;
				margin-bottom: 15px;
				width: 100%;

				&:not(.disabled) {
					cursor: pointer;
				}

				&:not(.disabled):hover {
					border-color: var(--divider);
				}

				.part {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 30px;

					.default-item {
						display: block;
						border-radius: 10px;
						padding: 2px 8px;
						background-color: #faece3;
					}

					.mandatory-container {
						border: 1px solid rgba($color: #000000, $alpha: 0.4);
						padding: 4px 10px;
						border-radius: 10px;

						span {
							display: flex;
							align-items: center;
							justify-content: center;
							position: relative;
							margin-left: 20px;

							&:before {
								content: "";
								position: absolute;
								left: -20px;
								width: 12px;
								height: 12px;
								background-color: var(--clr-error);
								border-radius: 6px;
							}
						}
					}

					.field-description {
						display: flex;
						flex-direction: column;
						span {
							font-weight: bold;

							&.type {
								font-weight: normal;
							}
						}

						.linked-count {
							display: inline-flex;
							flex-direction: row;
							margin-right: 10px;
							gap: 5px;
							color: #4589ff;
						}
					}
				}

				&.disabled {
					.part {
						> *:not(.default-item) {
							pointer-events: none;
							opacity: 0.5;
						}
					}
				}
			}
		}
	}
}
