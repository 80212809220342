.pair-device {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--clr-modal-background);

	.pair-device-center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		background-color: white;
		border-radius: var(--border-radius);
		border-radius: 15px;
		overflow: hidden;
		min-width: 300px;
		padding: 25px;

		h3 {
			font-family: "Gabarito";
		}

		.pair-device-header,
		.pair-device-footer {
			background-color: var(--clr-surface);
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 5px 10px;
			width: 100%;
		}

		.close-button,
		.add-button {
			font-family: "Gabarito";
			font-weight: 600;
			padding: 10px 20px;
			border-radius: 10px;
			font-size: 12px !important;
		}

		.add-button {
			background-color: #38ba7c;
			color: white;
			border: 2px solid #38ba7c;
			transition: 0.3s ease;

			&:hover {
				background-color: transparent;
				color: #38ba7c;
			}
		}

		.close-button {
			color: white;
			background-color: #f34642;
			border: 2px solid #f34642;
			transition: 0.3s ease;

			&:hover {
				background-color: transparent;
				color: #f34642;
			}
		}

		.pair-device-footer {
			justify-content: flex-end;

			button {
				margin-left: 10px;
			}
		}

		.pair-device-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding: 20px 10px;

			p {
				font-size: 1rem;
				padding-bottom: 15px;
				color: rgba($color: black, $alpha: 0.6);
				line-height: 1.3rem;

				span {
					font-weight: bold;
				}
			}

			svg {
				margin: 20px 0;
			}

			.big-number {
				margin: 30px 0;
				font-weight: bold;
				font-size: 4rem;
			}

			.name-input {
				margin: 20px 0;
			}

			.color-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				width: 100%;
				max-width: 360px;

				.color-elem {
					width: 32px;
					height: 32px;
					margin: 5px;
					border-radius: var(--border-radius);
					cursor: pointer;
					transition: 0.3s;

					&:hover,
					&.selected {
						border: 2px solid var(--clr-text);
					}
				}
			}

			.pair-device-account-selector {
				margin: 30px 0;
				border: 1px solid var(--clr-text);
				padding: 8px 24px;
				border-radius: var(--border-radius);
			}
		}
	}
}

#new-key-div {
	.pair-device-body {
		max-width: 400px;

		p {
			margin-bottom: 20px;

			&.key-value {
				border: 1px solid var(--clr-divider);
				border-radius: var(--border-radius);
				padding: 10px;
				width: 100%;
				word-wrap: break-word;
			}
		}
	}
}
