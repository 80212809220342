@import "../../../mixin.scss";

#event-base-view {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding-top: 60px;

	.save-changes {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		position: fixed;
		bottom: 0;
		right: 0;
		width: calc(100vw - 60px);
		background-color: var(--clr-surface);
		border-top: 1px solid var(--clr-divider);
		padding: 10px 20px;

		button:first-child {
			margin-right: 20px;
		}

		@media screen and (max-width: 900px) {
			& {
				width: calc(100vw - 60px);
			}
		}

		@include phone {
			& {
				width: 100%;
			}
		}
	}

	.event-navigation {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		top: 60px;
		left: 60px;
		height: 60px;
		width: calc(100% - 95px);
		background-color: var(--clr-surface);
		padding: 0 20px;
		z-index: 10;
		margin: 10px auto;
		right: 0;
		border-radius: 10px;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);

		@include phone {
			width: calc(100% - 40px);
			left: 20px;
			top: 70px;
			margin: 0;
		}

		li,
		.event-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			list-style: none;
			margin-right: 10px;
			gap: 10px;

			a {
				text-decoration: none;
				display: flex;
				padding: 10px;
				color: inherit;

				.account-nav-elem {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					transition: 0.3s ease;
					padding: 10px 20px;
					border-radius: 20px;
					font-family: "Gabarito";

					img {
						display: flex;
						justify-content: center;
						width: 15px;
						height: 15px;
						margin-right: 10px;
						font-size: 1rem;
						opacity: 0.85;
					}

					span {
						font-family: "Gabarito";
					}
				}

				&:hover {
					.account-nav-elem {
						background-color: rgba(0, 0, 0, 0.065);
						border-radius: 20px;
					}
				}
			}

			&.active {
				.account-nav-elem {
					background-color: rgba(0, 0, 0, 0.065);
				}
			}
		}

		.event-title-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-right: 30px;

			.title-skeleton {
				display: flex;
				flex-direction: row;
				align-items: center;

				span {
					&:first-child {
						width: 32px !important;
						height: 32px !important;
						border-radius: 10px;
						margin-right: 10px;
						display: block;
					}

					&:last-child {
						font-size: 1.1rem !important;
						font-family: "Gabarito";
					}
				}
			}

			img {
				height: 35px;
				width: 55px;
				border-radius: 5px;
				margin-right: 10px;
				object-fit: cover;
			}

			span {
				font-size: 1.2rem;
				font-weight: 100;
				font-family: "Gabarito";
				max-width: 160px;
			}
		}

		&.full {
			width: 100%;
		}
	}

	.event-content {
		padding: 30px;
		width: calc(100% - 35px);
		margin: 25px auto;
		margin-top: 45px;
		background-color: white;
		border-radius: 10px;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
	}
}
