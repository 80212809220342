@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@300;400;500;700&family=Lato:wght@300;400;500;700");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	--clr-background: #f4f6f7;
	--clr-background-transparent: rgba(244, 246, 247, 0.6);
	--clr-surface: #fff;
	--clr-surface-transparent: rgba(255, 255, 255, 0.8);
	--clr-text: #222222;
	--clr-strong-text: #000;
	--clr-secondary-text: #566573;
	--clr-on-surface: #000;
	--clr-primary: #e98862;
	--clr-primary-transparent: #faece3;
	--clr-error: #f55956;
	--clr-warning: #f7dc6f;
	--clr-success: #5abd8d;
	--clr-on-primary: #fff;
	--border-radius: 6px;
	--clr-divider: #d5dbdb;
	--clr-divider-light: #d5dbdb;
	--clr-deactivate: #d5dbdb;
	--box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	--clr-scroll-bar: #bfdeff;
	--clr-modal-background: rgba(0, 0, 0, 0.4);

	--chart-color1: #85c1e9;
	--chart-color2: #f5b7b1;
	--chart-color3: #df808d;
	--chart-color4: #cfd8dc;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Lato", "Arial", Times, serif;
}

a {
	color: var(--clr-text);
}

h1,
h2,
h3,
h4,
h5,
.title {
	font-family: "Gabarito", "Arial", Times, serif;
}

body {
	width: 100%;
	background-color: var(--clr-background);

	&.no-scroll {
		overflow: hidden;
	}
}

*:not(.keep-bar)::-webkit-scrollbar {
	width: 6px;
	background: transparent;
	position: fixed;
	height: 4px;
}
*:not(.keep-bar)::-webkit-scrollbar-thumb {
	background: var(--clr-scroll-bar);
	border-radius: 3px;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.button_primary {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 3px solid var(--clr-primary);
	background-color: var(--clr-primary);
	margin: 5px 0;
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
	font-size: 16px;
	outline: none;
	transition: 0.3s;
	color: var(--clr-on-primary);
	cursor: pointer;
	padding: 2px;
}

a.button_primary {
	text-decoration: none;
}

.box {
	padding: 10px 20px;
	background-color: var(--clr-surface);
	border-radius: var(--border-radius);
	box-shadow: var(--box-shadow);
}

.content {
	padding: 40px 0 0 60px;
}

.content.no-padding-left {
	padding: 40px 0 0 0;
}

@media (hover: hover) {
	.button_primary:hover {
		background-color: var(--clr-surface);
		color: var(--clr-primary);
	}
}

p {
	color: rgba(0, 0, 0, 0.65);
	line-height: 1.4rem;
}

.classic-black-span-btn {
	display: block;
	padding: 10px 30px;
	border-radius: 5px;
	background: #2c2c2c;
	color: #fff !important;
	text-decoration: none;
	font-size: 16px;
	font-family: "Gabarito";
	border: 1px solid #f8f8f8;
	transition: 0.3s;
	width: fit-content;
}

.classic-black-span-btn:hover {
	background-color: #464646;
}

.one-line-text {
	white-space: nowrap;
	overflow: hidden;
	display: block;
	text-overflow: ellipsis;
}

.ant-select-dropdown {
	z-index: 10000;
}

.ant-tooltip {
	z-index: 10000;
}

.ant-popover {
	z-index: 10000;
}

.ant-picker-dropdown {
	z-index: 10000;
}
