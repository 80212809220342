@import "../../../mixin.scss";

section.login {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 100vh;
	background-color: #fff;

	.logo-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 20px;
		left: 20px;

		img {
			width: 48px;
			height: 48px;
		}

		span {
			font-weight: bold;
			font-size: 1.2rem;
			margin-left: 10px;
		}
	}

	.center_div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 400px;
		max-width: 90%;

		.form {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 10px;
			margin-bottom: 20px;
			width: 100%;

			div:first-child {
				margin-bottom: 20px;
			}
		}

		.other-method-link {
			width: 100%;
			margin-top: 20px;
		}
	}

	.login-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40vw;
		min-width: 400px;
		padding: 100px 0;
		border-radius: 20px;
	}

	@media only screen and (max-width: 800px) {
		.login-container {
			width: 100%;
			min-width: unset;
		}
	}

	@include phone {
		& {
			min-height: 85vh;
		}
	}
}
