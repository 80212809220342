.center-new-participant-form-modal {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 30px;
	border-radius: 20px;
	width: 90%;
	max-width: 800px;

	.modal-header {
		width: 100%;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid var(--clr-divider);
	}

	.modal-content {
		width: 100%;
		max-height: calc(100svh - 300px);
		padding: 0 20px;

		overflow-y: auto;

		.field-row {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			gap: 20px;
			width: 100%;
			margin-bottom: 10px;
		}

		.field-block {
			width: 100%;
			.input {
				width: 100%;
				margin-top: 10px;
			}
		}

		.ticket-list {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-top: 20px;
			width: 100%;
			padding-bottom: 10px;

			.ticket-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 8px;
				border-radius: 8px;
				gap: 10px;
				background-color: #fff;
				border: 1px solid var(--clr-divider);

				.ant-select {
					min-width: 200px;
				}
			}
		}
	}

	.modal-footer {
		padding-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		gap: 20px;
	}
}
