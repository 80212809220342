.label-container-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;

	&.disabled {
		opacity: 0.4;
	}

	label {
		margin-bottom: 5px;
	}
}
