@import "../../../mixin.scss";

header {
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	border-bottom: 1px solid var(--clr-divider);
	background-color: var(--clr-surface);
	padding: 0 15px;
	z-index: 1000;

	.dev-banner {
		position: absolute;
		left: 0;
		top: 0;
		height: 60px;
		pointer-events: none;
		opacity: 0.8;
	}

	.pass-pass-logo {
		height: 36px;
		width: 82px;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.current-account {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-right: 20px;
		padding: 10px 25px;
		border-radius: 50px;
		transition: 0.3s;
		background-color: #faece3;

		span {
			margin-left: 10px;
		}

		&:hover {
			background-color: rgba($color: #000000, $alpha: 0.1);
		}
	}

	> ul {
		display: flex;
		flex-direction: row;
		align-items: center;

		li {
			text-decoration: none;
			list-style: none;
			margin: 0 10px;

			i {
				font-size: 1rem;
			}

			&.active i {
				color: var(--clr-primary);
			}
		}

		li.small {
			display: none;
		}

		@media screen and (max-width: 900px) {
			li.large {
				display: none;
			}

			li.small {
				display: flex;
			}
		}
	}

	.drawer-button {
		display: none;
	}

	@include phone {
		padding: 0 20px 0 20px;
		ul {
			display: none;
		}

		.drawer-button {
			display: flex;
		}
	}
}

.select-account-view {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--clr-modal-background);
	z-index: 10000;

	.select-account-center {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		background-color: var(--clr-background);
		background-color: white;
		border-radius: var(--border-radius);
		border-radius: 15px;
		overflow: hidden;
		min-width: 400px;
		padding: 30px;

		.close-button,
		.add-button {
			font-family: "Gabarito";
			font-weight: 600;
			padding: 6px 20px;
			border-radius: 10px;
			font-size: 12px !important;
		}

		.add-button {
			background-color: #38ba7c;
			color: white;
			border: 2px solid #38ba7c;
			transition: 0.3s ease;

			&:hover {
				background-color: transparent;
				color: #38ba7c;
			}
		}

		.close-button {
			color: white;
			background-color: #222222;
			border: 2px solid #222222;
			transition: 0.3s ease;

			&:hover {
				background-color: transparent;
				color: #222222;
			}
		}

		.select-account-header,
		.select-account-footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 5px 10px;
			width: 100%;
		}

		.select-account-footer {
			justify-content: flex-end;

			button {
				margin-left: 10px;
			}
		}

		.select-account-body {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			width: 100%;
			padding: 20px 10px;
			max-height: 50vh;
			overflow-y: auto;

			.account-elem {
				display: block;
				border-radius: var(--border-radius);
				background-color: var(--clr-surface);
				border: 1px solid rgba(0, 0, 0, 0.136) !important;
				width: 100%;
				margin: 5px 0;
				padding: 10px 15px;
				transition: 0.3s;
				cursor: pointer;

				&:hover,
				&.selected {
					border-color: var(--clr-primary);
					background-color: var(--clr-primary-transparent);
				}

				i {
					margin-right: 10px;
				}
			}

			.create-account {
				width: 100%;
				p {
					margin-bottom: 20px;
				}
			}
		}
	}
}

.drawer-content {
	background-color: var(--clr-background);
	padding: 10px;

	.current-account-block {
		display: flex;
		align-items: flex-start;
		margin-bottom: 20px;

		.current-account-picture {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--clr-primary-transparent);
			width: 32px;
			height: 32px;
			border-radius: 6px;
			margin-right: 10px;
		}
		span {
			color: var(--clr-secondary-text);
		}
	}

	ul {
		li {
			list-style: none;
			margin: 5px 0;
			padding: 5px;

			a {
				text-decoration: none;

				.navigation_elem {
					display: flex;
					align-items: center;

					i {
						color: var(--clr-deactivate);
						margin-right: 10px;
						width: 20px;
						text-align: center;
					}
					img {
						width: 16px;
						height: 16px;
						margin: 2px;
						margin-right: 10px;
						object-fit: cover;
					}
					span {
						color: var(--clr-secondary-text);
					}
				}
			}

			.separator {
				margin-top: 20px;

				h2 {
					font-size: 1rem;
					text-transform: uppercase;
					color: var(--clr-secondary-text);

					&.main {
						font-size: 1.1rem;
					}
				}
			}

			&.active {
				background-color: var(--clr-primary-transparent);
				border-radius: var(--border-radius);
				a {
					.navigation_elem {
						i {
							color: var(--clr-text);
						}
						span {
							color: var(--clr-text);
						}
					}
				}
			}
		}
	}
}
