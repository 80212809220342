.page-empty {
	width: 100%;
	height: calc(100vh - 60px);
	padding-top: 40px;
	padding-left: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h1 {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	h1,
	p {
		max-width: 80%;
		text-align: center;
	}

	.center-animation {
		width: 30%;
		max-width: 200px;
	}
}
