.passpass-table-container {
	.passpass-table-header {
		display: flex;
		flex-direction: column;

		h1 {
			font-size: 1.8rem;
		}

		.table-header-filters-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;

			> div {
				display: flex;
				align-items: center;
				gap: 10px;
			}

			.header-btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 5px;

				border: 1px solid var(--clr-divider);
				border-radius: 5px;
				padding: 5px;
				cursor: pointer;

				svg {
					font-size: 16px;
				}
			}
		}
	}
}

.passpass-table-column-selector {
	display: flex;
	flex-direction: column;
	gap: 5px;
	background-color: var(--clr-surface);
	box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
	border: 1px solid var(--clr-divider);
	border-radius: 5px;

	.column-selector-header {
		padding: 5px 10px;
		border-bottom: 1px solid var(--clr-divider);
	}

	.columns-container {
		padding: 5px;

		.column-item {
			padding: 5px;
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			border-radius: 5px;

			span {
				font-size: 14px;
				margin-left: 10px;
			}

			&:hover {
				background-color: rgba($color: #000000, $alpha: 0.1);
			}

			.hidden {
				fill: transparent;
			}
		}
	}
}
