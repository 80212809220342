.center-participation-form-modal {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	padding: 30px;
	border-radius: 20px;
	width: 90%;

	max-width: 800px;

	.modal-header {
		width: 100%;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid var(--clr-divider);
	}

	.modal-content {
		width: 100%;
		max-height: calc(100svh - 300px);
		padding: 0 20px;

		overflow-y: auto;

		.mandatory-field {
			display: flex;
			align-items: center;
			gap: 30px;
			margin-bottom: 30px;
		}

		.field-title-type {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.field-block {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}

		.optional-settings {
			margin-top: 40px;
			> span {
				font-size: 1.2rem;
				font-weight: bold;
			}

			.field-block {
				display: flex;
				flex-direction: column;
				width: calc(100%);
				margin-top: 20px;

				.input {
					width: 100%;
					margin-top: 10px;
				}
			}

			.activate {
				margin-top: 20px;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 20px;
			}

			.select-item {
				margin-top: 10px;
				min-width: 200px;
				max-width: 500px;
			}
		}

		.option-fields {
			margin-top: 40px;
			> span {
				font-size: 1.2rem;
				font-weight: bold;
			}

			.options-wrapper {
				margin: 20px 0;
				width: 100%;

				.option-field-item {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					background-color: rgba($color: #000000, $alpha: 0.02);
					border-radius: 10px;
					padding: 15px;
					margin-bottom: 20px;

					.left {
						display: flex;
						align-items: center;
						gap: 10px;
						width: 80%;
					}

					.input {
						width: 100%;
					}
				}
			}
		}
	}

	.modal-footer {
		padding-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		gap: 20px;
	}
}
