.dashboard-last-article {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-decoration: none;
	height: 100%;
	transition: 0.3s;

	.article-top {
		width: 100%;
	}

	.article-header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		width: 100%;
		margin-bottom: 15px;

		.tag-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;

			.tag {
				font-family: Gabarito;
				border-radius: 4px;
				padding: 4px 10px;
				text-transform: uppercase;

				span {
					font-family: "Gabarito" !important;
					font-weight: 500;
					font-size: 12px;
				}
			}
		}

		.date {
			font-family: Gabarito;
			font-size: 14px;
		}
	}

	.article-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.read-btn {
			display: block;
			padding: 10px 30px;
			border-radius: 5px;
			background: #2c2c2c;
			color: #fff;
			text-decoration: none;
			font-size: 16px;
			font-family: "Gabarito";

			border: 1px solid #f8f8f8;
		}
	}

	&:hover {
		.article-footer {
			.read-btn {
				background-color: rgba($color: #2c2c2c, $alpha: 0.75);
			}
		}
	}
}
