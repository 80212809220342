.event-dashboard-content {
	margin-top: 30px;
	padding: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	gap: 15px;
	width: 100%;

	.divider {
		display: block;
		margin: 10px 0;
		background-color: rgba($color: #000000, $alpha: 0.1);
		height: 1px;
		width: 100%;
	}

	.event-dashboard-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background-color: #fff;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.065);
		border-radius: 10px;
		height: 100%;
		padding: 20px;

		.graph-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			gap: 20px;

			&.pie-container {
				* {
					outline: none !important;
				}

				.legend {
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 5px;

					.legend-item {
						display: flex;
						flex-direction: row;
						align-items: center;
						width: fit-content;

						.color {
							display: block;
							width: 30px;
							height: 15px;
							border-radius: 5px;
							margin-right: 10px;
						}

						.label {
							width: 190px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-family: "Gabarito";
							font-size: 14px;
							color: rgba($color: #202020, $alpha: 0.8) !important;
						}
					}
				}
			}

			&.line-container {
				margin-top: 20px;
				width: 100%;

				.recharts-surface {
					margin: 0 !important;
					padding: 0 !important;
				}

				tspan {
					font-size: 12px !important;
				}

				.recharts-legend-item-text {
					font-family: "Gabarito";
					font-size: 14px;
					color: rgba($color: #202020, $alpha: 0.8) !important;
					padding-left: 6px;
					padding-right: 8px;
				}

				.recharts-legend-wrapper {
					width: fit-content !important;
					padding-left: 40px;
				}
			}
		}

		.title {
			font-size: 1.3rem;
			font-weight: bold;
			color: rgba($color: #000000, $alpha: 0.9);

			&.secondary {
				color: rgba($color: #000000, $alpha: 0.7);
				font-size: 1rem;
			}
		}

		.ticket-sold {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;

			margin-top: 10px;
			* {
				font-family: "Gabarito";
			}

			.big {
				font-weight: bold;
				font-size: 1.8rem;
				padding-right: 6px;
			}

			.total-earn {
				display: flex;
				flex-direction: column;
				gap: 5px;
				background-color: #f8f8f8;
				padding: 10px 15px;
				min-width: 200px;
				border-radius: 5px;
			}
		}

		&.thin {
			grid-column: span 2;
			grid-row: span 2;
		}

		&.large {
			grid-column: span 4;
			grid-row: span 2;
		}

		&.tall {
			grid-row: span 3;
		}

		&.small {
			grid-row: span 1;
		}

		&.intro-content {
			.buttons-container {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-start;
				margin-top: 20px;
				gap: 5px;

				.button {
					border-radius: 5px;
					background: rgba(217, 217, 217, 0.5);
					color: #000;
					text-decoration: none;
					cursor: pointer;
					padding: 5px 10px;
					transition: 0.2s;

					&:hover {
						background: rgba(217, 217, 217, 0.8);
					}
				}
			}
		}

		.last-reservations {
			width: 100%;
			overflow-y: auto;
			max-height: 240px;

			table {
				width: 100%;
				border-collapse: collapse;
				overflow-y: scroll;
				tr {
					td {
						padding: 10px 3px;
					}
				}
			}
		}
	}
}
