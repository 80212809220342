#demo {
	min-height: calc(100svh);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.demo-center {
		max-width: 1500px;
		width: 100%;
		padding: 5%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	h1 {
		color: #322f2d;
		font-size: 48px;
		font-weight: 600;
	}

	p {
		max-width: 500px;
		color: #191919;
		font-size: 16px;
		font-weight: 300;
	}

	.links-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-top: 25px;
		flex-wrap: wrap;

		a {
			display: block;
			border-radius: 10px;
			background: #fff;
			border: 1px solid #000;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
			text-decoration: none;
			color: #000;
			padding: 10px 20px;
			transition: 0.3s;

			&:hover {
				background-color: rgba($color: #000000, $alpha: 0.05);
			}

			&.home {
				color: #fff;
				background-color: #000;

				&:hover {
					background-color: rgba($color: #000000, $alpha: 0.7);
				}
			}
		}
	}
}
