.map-modal-center {
	max-width: 90vw;
	max-height: 90svh;
	gap: 20px;
	background-color: var(--clr-background);
	padding: 20px 40px;
	border-radius: 20px;

	> span {
		font-weight: bold;
		font-size: 1.1rem;
	}

	> p {
		display: block;
		margin: 10px 0 20px 0;
	}

	.map-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 600px;
		max-width: 100%;
		height: 400px;
		max-height: 50svh;
		border-radius: 10px;
		overflow: hidden;

		.pin-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			z-index: 1000;
			transform: translateY(-18px);

			svg {
				width: 40px;
				height: 40px;
				transition: 0.3s;
			}

			.pin-shadow {
				height: 4px;
				width: 40px;
				background-color: rgba($color: #000000, $alpha: 0.3);
				border-radius: 100%;
				transition: 0.3s;
			}

			&.up {
				svg {
					transform: translateY(-30px);
				}

				.pin-shadow {
					transform: scaleX(0.3);
				}
			}
		}
	}

	.modal-footer {
		padding-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		gap: 20px;
	}
}
