.create-update-center {
	display: flex;
	flex-direction: column;
	width: calc(100% - 240px - 360px);
	padding: 20px;
	margin-left: 240px;
	margin-top: 30px;

	h1 {
		margin: 10px 0 30px 0;
		font-family: "Gabarito";
	}

	.label-max-length {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		span {
			color: rgba($color: #000000, $alpha: 0.6);
		}
	}

	.address-clear {
		padding-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		gap: 20px;
	}

	.global-fill-btn {
		background-color: #222222;
		color: white;
		padding: 10px 30px;
		border-radius: 10px;
		cursor: pointer;
		font-size: 1rem;
		width: fit-content;

		&:hover {
			background-color: #222222b5;
			color: white;
		}
	}

	.delete-btn {
		background-color: rgb(187, 54, 54);
		font-size: 12px;
		color: white;
		border-radius: 10px;
		margin-left: 20px;

		&:hover {
			background-color: rgba(187, 54, 54, 0.813);
		}
	}

	.go-back-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		transition: 0.3s;

		span {
			font-size: 12px;
			color: var(--clr-secondary-text);
		}

		svg {
			fill: var(--clr-secondary-text);
			font-size: 12px;
		}

		&:hover {
			span {
				color: var(--clr-text);
				font-weight: bold;
			}

			svg {
				fill: var(--clr-text);
			}
		}
	}

	.block-picker {
		box-shadow: var(--box-shadow) !important;
	}

	.classic-text-input {
		width: 100%;
		border: 0.5px solid rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		outline: none;
		padding: 12px 10px;
		margin-top: 5px;
		margin-bottom: 15px;
	}

	.color-input {
		margin-top: 10px;
	}

	.classic-text-area {
		width: 100%;
		border: 0.5px solid rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		outline: none;
		padding: 12px 10px;
		margin-top: 5px;
		margin-bottom: 15px;
		resize: none;
	}

	.ck-editor-container {
		width: 100%;
		padding-top: 10px;

		li {
			margin-left: 15px;
		}
	}

	.classic-date-input {
		border: 0.5px solid rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		outline: none;
		padding: 12px 10px;
		margin-top: 5px;
		margin-bottom: 15px;
	}

	.file-input-container {
		width: 100%;
		border: 0.5px solid rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 20px;

		&.clickable {
			cursor: pointer;
		}

		&.margin {
			margin-bottom: 20px;
		}

		input {
			display: none;
		}

		& > * + * {
			margin-top: 10px;
		}

		.image-container {
			width: 100%;
			max-width: 400px;
			height: 300px;
			position: relative;
			border-radius: 5px;
			border: 1px solid #fff;
			box-shadow: var(--box-shadow);
			overflow: hidden;

			.image-header {
				display: flex;
				flex-direction: row;
				position: absolute;
				top: 0;
				width: 100%;
				align-items: center;
				justify-content: space-between;
				background-color: rgba($color: #000, $alpha: 0.6);

				svg {
					fill: #fff;
				}
			}

			img {
				width: 100%;
				height: 300px;
				object-fit: cover;
			}

			&.gallery {
				height: 200px;

				img {
					height: 200px;
				}
			}
		}
	}

	.input-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		background-color: var(--clr-surface);
		box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.02);
		border-radius: var(--border-radius);
		padding: 15px;
		max-width: 800px;
		margin-bottom: 30px;

		> h2,
		> p {
			margin-bottom: 15px;
		}

		> p {
			font-weight: 300;
		}

		h2 {
			font-family: "Gabarito";
		}
	}

	.tickets-addon-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.ticket-addon-wrapper {
			display: flex;
			flex-direction: column;
			margin-top: 20px;
			width: 100%;

			.ticket-addon {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				background-color: var(--clr-surface);
				box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.02);
				border-radius: var(--border-radius);
				padding: 15px;
				max-width: 800px;
				margin-bottom: 30px;

				.ticket-addon-left {
					display: flex;
					flex-direction: row;
					align-items: center;

					.order-container {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						font-size: 10px;
					}

					.ticket-title {
						margin-left: 10px;

						span {
							font-weight: bold;
						}
					}
				}

				.input-fields {
					display: flex;
					flex-direction: row;
					gap: 10px;

					& > {
						max-width: 200px;
					}
				}
			}
		}
	}

	.summary-container {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		background-color: var(--clr-surface);
		border-radius: var(--border-radius);
		margin-bottom: 20px;
		padding: 10px;
		gap: 20px;

		.edit-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 32px;
			width: 32px;
			background-color: rgba($color: #000000, $alpha: 0.05);
			border-radius: 16px;
			font-size: 16px;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;

			svg {
				font-size: 16px;
			}

			&.fixed {
				position: unset;
				top: unset;
				right: unset;
			}
		}

		.row {
			display: flex;
			align-items: baseline;
			gap: 10px;
		}

		.color-container {
			display: flex;
			align-items: center;
			margin-right: 10px;
			gap: 10px;

			.color-block {
				width: 32px;
				height: 32px;
				border-radius: 8px;
			}
		}

		.event-value-container {
			display: flex;
			flex-direction: column;

			&.header {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}

			&:not(.header) {
				.title {
					margin-bottom: 10px;
				}
			}

			.description-container {
				span {
					color: var(--clr-secondary-text);
				}
				.show-more-less-clickable {
					color: var(--clr-primary);
					cursor: pointer;
				}

				.description-content {
					display: flex;
					flex-direction: column;
				}
			}
		}

		table.ticket-wrapper {
			border-collapse: collapse;
			width: 100%;

			tr:not(:first-child) {
				td {
					border-top: 0.5px solid var(--clr-divider);
				}
			}

			tr {
				td {
					padding: 5px 10px;

					p.one-line {
						white-space: nowrap;
						overflow: hidden;
						display: block;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}
