#event-ticket {
	padding: 20px;
	width: 100%;

	padding: 30px;
	width: calc(100% - 35px);
	margin: 50px auto;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);

	a {
		color: black;
	}
}

.passpass-single-pages {
	display: flex;
	flex-direction: column;
	gap: 40px;

	.divider {
		display: block;
		width: 100%;
		background-color: var(--clr-divider);
		height: 0.5px;
		margin: 20px 0;
	}

	.single-pages-block {
		.title {
			font-size: 1.2rem;
			font-weight: bold;
		}

		.info-container {
			display: flex;
			align-items: flex-start;

			.info-item {
				&:not(:first-child) {
					position: relative;
					padding: 0 20px;

					&::before {
						content: "";
						display: block;
						width: 0.5px;
						height: 100%;
						background-color: var(--clr-divider);
						position: absolute;
						top: 0;
						left: 0;
					}
				}

				&:first-child {
					padding-right: 20px;
				}

				.info-name {
					display: block;
					color: var(--clr-secondary-text);
					margin-bottom: 5px;
				}
			}
		}
	}
}
