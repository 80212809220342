.dashboard-main-view {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px 20px;
}

.dashboard-view-main-title-header {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	margin-top: 20px;

	h1 {
		font-family: "Gabarito";
	}
}

.table-value-status {
	min-width: 40px;
	width: fit-content;
	padding: 2px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	color: rgba($color: #000000, $alpha: 0.5);
	font-weight: bold;

	&.bool {
		width: fit-content;
		&.true {
			background-color: var(--clr-success);
		}

		&.false {
			background-color: var(--clr-error);
		}
	}

	&.icon {
		* {
			color: rgba($color: #000000, $alpha: 0.5);
		}
		display: flex;
		flex-direction: row;
		align-items: center;

		i {
			display: block;
			margin-left: 5px;
		}
	}
}

.table-value-link {
	color: #faece3;
}
