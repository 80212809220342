.page-loading-full {
	width: 100%;
	height: calc(100vh - 60px);
	padding-top: 40px;
	padding-left: 60px;
	display: flex;
	align-items: center;
	justify-content: center;

	.center-animation {
		width: 30%;
		max-width: 200px;
	}
}
