#event-participants {
	padding: 30px;
	width: calc(100% - 35px);
	margin: 50px auto;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
	position: relative;

	.export-form {
		display: flex;
		position: absolute;
		top: 15px;
		right: 20px;

		span {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	a {
		color: black;
	}
}
