#dashboard-event-list {
	.create-event-btn {
		background-color: #191919;
		border-radius: 10px;
		padding: 10px;
		width: 220px;

		span {
			color: white;
			font-family: "Gabarito";
			transition: 0.3s ease;
			margin-left: -5px;
		}

		img {
			width: 20px;
			height: 20px;
			opacity: 0;
			transition: 0.3s ease;
			transform: rotate(90deg);
			margin-right: -15px;
		}

		&:hover {
			span {
				margin-left: 5px;
			}
			img {
				opacity: 1;
				transform: rotate(0deg);
				margin-right: 0;
			}
		}
	}

	.event-list-table {
		border-collapse: separate;
		margin-top: 0px;
		border-spacing: 0 15px;
		padding: 0px 0;
		thead {
			tr {
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);
				border-radius: 10px;
				background-color: white;

				th {
					text-align: start;
					padding: 15px;

					&:first-child {
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}

					&:last-child {
						border-top-right-radius: 10px;
						border-bottom-right-radius: 10px;
					}

					.event-status-toggle {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;

						gap: 10px;
					}
				}
			}
		}

		tbody {
			tr {
				background-color: rgb(255, 255, 255);
				border-collapse: separate;
				border-spacing: 10px 5px;
				border-radius: 10px;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.065);

				td {
					padding: 15px;
					border-collapse: seperate;
					cursor: pointer;
					transition: 0.3s;

					&:first-child {
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}

					&:last-child {
						border-top-right-radius: 10px;
						border-bottom-right-radius: 10px;
					}

					.event-main-card {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						img {
							width: 200px;
							height: 128px;
							border-radius: 5px;
							margin-right: 10px;
							object-fit: cover;
							transition: 0.3s ease;
						}
					}

					.card-content {
						display: flex;
						flex-direction: column;
						gap: 10px;
						justify-content: left;
						margin-left: 25px;

						h2 {
							font-family: "Gabarito";
							font-weight: 600;
							font-size: 20px;
						}
					}

					.sold {
						display: flex;
						flex-direction: column;

						.sold-container {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							width: 240px;

							&:first-child {
								margin-bottom: 10px;
							}

							.sold-content {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								width: 160px;
								height: 20px;
								border-radius: 10px;
								position: relative;
								overflow: hidden;
								background-color: rgba($color: #000000, $alpha: 0.1);

								.sold-amount {
									position: absolute;
									top: 0;
									left: 0;
									height: 30px;
									width: 100%;
									background-color: var(--clr-primary);
								}

								.content {
									padding: 4px 8px;
									font-weight: bold;
									z-index: 1;
									font-size: 16px;

									span {
										font-size: 13px;
										font-weight: light;
										color: rgba(0, 0, 0, 0.651);
									}
								}
							}
						}
					}
				}

				&:hover {
					box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.14);
					background-color: #19191902;

					.event-main-card {
						img {
							width: 200px;
							height: 128px;

							transform: scale(1.025);
							box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.14);
						}
					}
				}
			}
		}
	}
}

#basic-event-menu {
	.item {
		&.error {
			* {
				color: var(--clr-error);
			}
		}
	}
}
